import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import LearnMore from "../templates/learnMore";
import meeting_room from "../images/meeting_room.jpg";

function IndexPage() {
  return (
    <Layout>
      <SEO
        keywords={[`gatsby`, `tailwind`, `react`, `tailwindcss`]}
        title="Meeting Rooms"
      />
      <LearnMore
        title={` The perfect space for 
        your Monday meetings`}
        cta={`Get in touch`}
        image={meeting_room}
      >
        <div>
          <p className="text-xl">
            Whether it’s impressing prospective clients or having the space to
            stretch out with your keyboard, collective_100 offers a range of
            premium meeting room spaces to hire at the touch of a button. You’ll
            make an instant impression with our boutique aesthetic, complete
            with state-of-the-art Nespresso machine, modern technology
            connectivity and optional catering. We provide you with the
            essentials, so you can make your presentation, meeting, interview or
            workshop an effortless success.
          </p>
        </div>
        <div>
          <p className="text-xl pt-8 md:pt-0">
            Our Cremorne location is incredibly simple to get to, with Richmond
            and Richmond East train stations mere minutes away, allowing your
            clients to arrive at ease. For those driving, private parking can be
            arranged at a small fee to ensure convenience, especially ideal for
            meetings that require presentation or collateral.{" "}
          </p>
        </div>
      </LearnMore>
    </Layout>
  );
}

export default IndexPage;
